import { Dialog, DialogActions, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Builder, Query, Utils as QbUtils } from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/compact_styles.css";
import "react-awesome-query-builder/lib/css/styles.css";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "../../components/custom-button";
import MaterialConfig from "../../components/material-v5-widget/config";
import ContactContext from "../../context/contact-context";
import "../../scss/query-builder.scss";

const InitialConfig = MaterialConfig;

const config = {
  ...InitialConfig,
  fields: {
    "scenarios.buying_power": {
      label: "Buying Power",
      type: "numberWithDollar",
      fieldSettings: {
        min: 0,
      },
      valueSources: ["value"],
      preferWidgets: ["numberWithDollar"],
    },
    "userFinance.debt_outstanding": {
      label: "Outstanding Debt",
      type: "numberWithDollar",
      fieldSettings: {
        min: 0,
      },
      operators: [
        "equal",
        "less",
        "less_or_equal",
        "greater",
        "greater_or_equal",
      ],
      valueSources: ["value"],
      preferWidgets: ["numberWithDollar"],
    },
    "userFinance.lvr": {
      label: "Current LVR",
      type: "number",
      fieldSettings: {
        min: 0,
        max: 100,
      },
      operators: [
        "equal",
        "less",
        "less_or_equal",
        "greater",
        "greater_or_equal",
      ],
      defaultValue: 0,
      preferWidgets: ["number"],
    },
    last_login_time: {
      label: "Last Visit App",
      type: "date",
      defaultValue: new Date(),
      operators: [
        "equal",
        "less",
        "less_or_equal",
        "greater",
        "greater_or_equal",
        "between",
        "not_between",
      ],
    },

    "scenarios.process_status": {
      label: "Position On Kanban",
      type: "select",
      operators: ["select_equals", "select_not_equals"],
      fieldSettings: {
        listValues: [
          {
            value: "Lead",
            title: "Waiting For Pre Approved",
          },
          { value: "Lodged", title: "Lodged" },
          { value: "Conditional", title: "Conditional" },
          { value: "Unconditional", title: "Unconditional" },
          {
            value: "Pending Settlement",
            title: "Pending Settlement",
          },
          { value: "Settled", title: "Settled" },
        ],
      },
    },
  },
};

export const queryValue = {
  id: uuidv4(),
  type: "group",
  children1: {
    [uuidv4()]: {
      type: "rule",
      properties: {
        field: null,
        operator: null,
        value: [],
        valueSrc: [],
      },
    },
  },
};

const ContactFilterDialog = ({
  isOpen = false,
  handleOpenDialog = () => {},
}) => {
  const {
    handleCreateFilter,
    filterData,
    setFilterData,
    selectedContactFilter,
    handleUpdateFilter,
    isUpdateFilter,
  } = useContext(ContactContext);
  const [state, setState] = useState({});
  const [filterNameInput, setFilterNameInput] = useState("");
  const callingFunctionRef = useRef(false);

  useEffect(() => {
    if (selectedContactFilter) {
      setFilterNameInput(filterData.filterName);
      setState({
        tree: QbUtils.checkTree(
          QbUtils.loadTree(filterData?.filterSegment),
          config
        ),
        config,
      });
    } else {
      setFilterNameInput("");
      setState({
        tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
        config,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onChange = (immutableTree, newConfig) => {
    setState({ tree: immutableTree, config: newConfig });
  };
  const handleInputFilterName = (e) => {
    setFilterNameInput(e.target.value);
  };

  const handleSendDataAndCreateFilter = () => {
    const jsonTree = QbUtils.getTree(state.tree);
    setFilterData((prevState) => ({
      ...prevState,
      filterName: filterNameInput || "New Filter",
      filterSegment: jsonTree,
    }));
    callingFunctionRef.current = true;
  };

  useEffect(() => {
    if (callingFunctionRef.current) {
      callingFunctionRef.current = false;
      if (isUpdateFilter) {
        handleUpdateFilter();
      } else {
        handleCreateFilter();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  return (
    <Dialog
      onClose={handleOpenDialog}
      aria-labelledby="customized-dialog-title"
      className="contact-filter-dialog"
      open={isOpen}
    >
      <div
        className="contact-filter-dialog__title"
        id="customized-dialog-title"
        onClose={handleOpenDialog}
      >
        Create New Segment
      </div>
      <div>
        <TextField
          id="filter-name"
          label="Filter name"
          variant="outlined"
          value={filterNameInput}
          onChange={handleInputFilterName}
        />
      </div>

      <div className="contact-filter-dialog__query-builder">
        <Query
          {...config}
          value={state.tree}
          onChange={onChange}
          renderBuilder={renderBuilder}
        />
      </div>
      <DialogActions>
        <CustomButton
          autoFocus
          onClick={handleSendDataAndCreateFilter}
          label={isUpdateFilter ? "Update Segment" : "Create Segment"}
        />
      </DialogActions>
    </Dialog>
  );
};

ContactFilterDialog.propTypes = {};

export default ContactFilterDialog;
