import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

function descendingComparator(a, b, orderBy) {
  const valueA = a[orderBy] || 0;
  const valueB = b[orderBy] || 0;
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) {
      return order;
    }
    return a - b;
  });
  return stabilizedThis;
}

const EnhancedTableHead = ({ order, orderBy, onRequestSort, headerList }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headerList.map((headCell) => {
          return (
            <TableCell
              className="contact-list__header"
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={
                  headCell.id !== "contactName" &&
                  headCell.id !== "contactDetail"
                    ? createSortHandler(headCell.id)
                    : undefined
                }
                hideSortIcon={
                  headCell.id === "contactName" ||
                  headCell.id === "contactDetail" ||
                  headCell.id === "actionButton"
                }
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const ContactTable = ({
  tableHeader: columns = [],
  contactList: rows = [],
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className="contact-list__container">
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            headerList={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.contactDetail.email}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === "contactDetail") {
                        return (
                          <TableCell
                            key={`${row.contactDetail.email} ${column.id}`}
                            align={column.align}
                          >
                            <div>Email: {value.email}</div>
                            <div>Mobile: {value.phoneNumber}</div>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={`${row.contactDetail.email} ${column.id}`}
                          align={column.align}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="contact-list__pagination">
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Items per page"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Pagination
          count={Math.ceil(rows.length / rowsPerPage)}
          shape="rounded"
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

ContactTable.propTypes = {
  tableHeader: PropTypes.array,
  contactList: PropTypes.array,
};

export default ContactTable;
