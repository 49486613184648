import axiosClient from "./axiosClient";

const getContactList = () => {
  const url = "/contact";
  return axiosClient.get(url);
};

const createContactFilter = (contactFilterParams) => {
  const url = "/user-filter";
  return axiosClient.post(url, contactFilterParams);
};

const getContactFilter = () => {
  const url = "/user-filter";
  return axiosClient.get(url);
};

const updateContactFilter = ({ id, contactFilterParams }) => {
  const url = `/user-filter/${id}`;
  return axiosClient.put(url, contactFilterParams);
};

const deleteContactFilter = (id) => {
  const url = `/user-filter/${id}`;
  return axiosClient.delete(url);
};

const getContactListByFilterId = (id) => {
  const url = `/contact/filter/${id}`;
  return axiosClient.get(url);
};

export {
  getContactList,
  createContactFilter,
  getContactFilter,
  getContactListByFilterId,
  updateContactFilter,
  deleteContactFilter,
};
