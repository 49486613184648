import { useContext } from "react";
import SearchInputComponent from "../../components/contact-search-input";
import ContactContext from "../../context/contact-context";

const ContactSearch = () => {
  const { handleContactSearch } = useContext(ContactContext);
  return (
    <div className="contact-search-contact__container">
      <div className="contact-search-contact__title">Contact search</div>
      <div className="contact-search-contact__input">
        <SearchInputComponent handleSearch={handleContactSearch} />
      </div>
    </div>
  );
};

ContactSearch.propTypes = {};

export default ContactSearch;
