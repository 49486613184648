import PropTypes from "prop-types";
import React, { useContext } from "react";
import ContactContext from "../../context/contact-context";
import AddContactFilter from "./add-contact-filter";
import { queryValue } from "./contact-filter-dialog";
import ContactFilterItem from "./contact-filter-item";

const ContactFilterList = ({ filterList = [] }) => {
  const {
    selectedContactFilter,
    handleSelectContactFilter,
    handleDialog,
    setFilterData,
    setIsUpdateFilter,
  } = useContext(ContactContext);
  const handleAddFilter = () => {
    setIsUpdateFilter(false);
    handleDialog();
    setFilterData({
      filterName: "",
      filterSegment: queryValue,
    });
  };
  return (
    <>
      {filterList.map((filterItem) => {
        const {
          id,
          filterName,
          filterSegment,
          contactQuantity = 0,
        } = filterItem;
        return (
          <ContactFilterItem
            key={id}
            filterName={filterName}
            filterSegment={filterSegment}
            value={contactQuantity}
            filterId={id}
            onClickFilter={handleSelectContactFilter}
            isSelected={selectedContactFilter === id}
          />
        );
      })}
      <AddContactFilter onClick={handleAddFilter} />
    </>
  );
};

ContactFilterList.propTypes = {
  filterList: PropTypes.array,
};

export default ContactFilterList;
