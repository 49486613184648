import PropTypes from "prop-types";
import ContactFilterList from "./contact-filter-list";

const ContactFilter = ({ filterList = [] }) => {
  return (
    <div className="contact-filter">
      <div className="contact-filter__title">Segment Groups</div>
      <div className="contact-filter__container">
        <ContactFilterList filterList={filterList} />
      </div>
    </div>
  );
};

ContactFilter.propTypes = {
  filterList: PropTypes.array,
};

export default ContactFilter;
