import React from "react";
import PropTypes from "prop-types";
import Plus from "../../svgs/Plus";

const AddContactFilter = ({ onClick = () => {} }) => {
  return (
    <div className="contact-filter-item__container" onClick={onClick}>
      <div>Add Filter</div>
      <div className="contact-filter-item__icon-container">
        <Plus />
      </div>
    </div>
  );
};

AddContactFilter.propTypes = {
  onClick: PropTypes.func,
};

export default AddContactFilter;
