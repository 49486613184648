import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { getAppUrl } from "../../api-services/axiosClient";
import HeaderComponent from "../../components/HeaderComponent";
import { CustomOutlineButton } from "../../components/custom-button";
import ContactContext from "../../context/contact-context";
import ContactFilter from "../../features/contact/contact-filter";
import ContactFilterDialog from "../../features/contact/contact-filter-dialog";
import ContactSearch from "../../features/contact/contact-search";
import ContactTable from "../../features/contact/contact-table";
import ShareDetailsDialog from "../../features/contact/share-details-dialog";
import TotalContact from "../../features/contact/total-contact";
import {
  useContactFilter,
  useContactList,
  useCreateFilter,
  useGetContactListByFilter,
  useUpdateFilter,
} from "../../hooks/contact.hook";
import { monthDayFormater } from "../../utils/calendar-util";
import { formatNumberWithDollar } from "../../utils/numberFormater";
import { currentAuthenticatedUser } from "../../utils/user-auth-provider";

const formatContactNumber = (value) => {
  return typeof value === "number" || value === 0
    ? formatNumberWithDollar(value)
    : "Not Available";
};

const columns = [
  { id: "contactName", label: "Contact Name", minWidth: 170 },
  { id: "contactDetail", label: "Contact Details", minWidth: 100 },
  {
    id: "buyingGoal",
    label: "Buying Goal",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "buyingPower",
    label: "Buying Power",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "cashAvailable",
    label: "Cash Available",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "debtOutstanding",
    label: "Debt Outstanding",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "lastAppVisit",
    label: "Last App Vist",
    minWidth: 100,
  },
  {
    id: "actionButton",
    label: "",
    minWidth: 100,
  },
];

const createData = ({
  contactName,
  contactDetail,
  buyingGoal,
  buyingPower,
  cashAvailable,
  debtOutstanding,
  lastAppVisit,
  handleViewAccount,
  handleShareDetail,
}) => {
  return {
    contactName,
    contactDetail,
    buyingGoal,
    buyingPower,
    cashAvailable,
    debtOutstanding,
    lastContact: lastAppVisit || "Not Available",
    lastAppVisit: lastAppVisit || "Not Available",
    actionButton: (
      <div className="contact-table__action-button-container">
        <CustomOutlineButton
          label="View Account"
          onClick={handleViewAccount}
          buttonType="secondary"
        />
        <CustomOutlineButton
          label="Share Details"
          onClick={handleShareDetail}
          buttonType="secondary"
        />
      </div>
    ),
  };
};

const ContactPage = () => {
  const queryClient = useQueryClient();
  const useContactListQuery = useContactList();
  const { data: contactList = [] } = useContactListQuery;
  const [selectedContactFilter, setSelectedContactFilter] = useState("");
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenShareDetailDialog, setIsOpenShareDetailDialog] = useState(false);
  const [isUpdateFilter, setIsUpdateFilter] = useState(false);
  const [contactSearchQuery, setContactSearchQuery] = useState("");
  const [filterData, setFilterData] = useState({});
  const [selectedContact, setSelectedContact] = useState("");
  const handleViewAccount = async (userId) => {
    const authUser = await currentAuthenticatedUser();
    const authUserName = authUser.username;
    window.open(
      `${getAppUrl()}/?viewAsUserId=${userId}&authUserName=${authUserName}`
    );
  };

  const { data: filterList = [] } = useContactFilter();

  const { data: contactListByFilter, isSuccess } = useGetContactListByFilter(
    selectedContactFilter
  );
  const handleSelectContactFilter = (value) => {
    setSelectedContactFilter((prevValue) => (prevValue === value ? "" : value));
  };

  const handleContactSearch = (value) => {
    setContactSearchQuery(value);
  };
  const handleDialog = () => {
    setIsOpenDialog(!isOpenDialog);
  };
  const handleShareDetailDialog = useCallback(
    (id) => {
      setSelectedContact(id);
      setIsOpenShareDetailDialog(!isOpenShareDetailDialog);
    },
    [isOpenShareDetailDialog]
  );

  const createFilter = useCreateFilter();
  const { mutate: updateFilter } = useUpdateFilter();

  const handleCreateFilter = () => {
    createFilter.mutate(filterData, {
      onSuccess: (result) => {
        handleDialog();
        queryClient.invalidateQueries("filterList");
        handleSelectContactFilter(result.id);
      },
    });
  };

  const handleUpdateFilter = () => {
    updateFilter(
      {
        id: selectedContactFilter,
        contactFilterParams: filterData,
      },
      {
        onSuccess: () => {
          handleDialog();
          queryClient.invalidateQueries("filterList");
          queryClient.invalidateQueries([
            "contactListById",
            selectedContactFilter,
          ]);
        },
      }
    );
  };

  const buildContactListData = useMemo(() => {
    const contactListData =
      selectedContactFilter && isSuccess ? contactListByFilter : contactList;
    return contactListData
      ?.filter(
        (contact) =>
          `${contact.firstName} ${contact.lastName}`
            .trim()
            .toLowerCase()
            .includes(contactSearchQuery.toLowerCase()) ||
          contact.email
            .toLowerCase()
            .includes(contactSearchQuery.toLowerCase()) ||
          contact.phoneNumber.includes(contactSearchQuery.toLowerCase())
      )
      .map((contact) => {
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          lastLogin,
          id,
          buyingGoal,
          buyingPower,
          cashAvailable,
          debtOutstanding,
          createdAt,
        } = contact;
        const contactName = `${firstName} ${lastName}`.trim();
        const isLastLoginAvailable = !!lastLogin;
        return createData({
          contactName,
          contactDetail: {
            email,
            phoneNumber,
          },
          buyingGoal,
          buyingPower,
          cashAvailable,
          debtOutstanding,
          lastAppVisit: isLastLoginAvailable
            ? monthDayFormater(new Date(lastLogin))
            : monthDayFormater(new Date(createdAt)),

          handleViewAccount: () => handleViewAccount(id),
          handleShareDetail: () => handleShareDetailDialog(id),
        });
      });
  }, [
    contactList,
    contactListByFilter,
    contactSearchQuery,
    handleShareDetailDialog,
    isSuccess,
    selectedContactFilter,
  ]);
  return (
    <ContactContext.Provider
      value={{
        handleSelectContactFilter,
        selectedContactFilter,
        handleDialog,
        handleShareDetailDialog,
        handleContactSearch,
        handleCreateFilter,
        filterData,
        setFilterData,
        isUpdateFilter,
        setIsUpdateFilter,
        handleUpdateFilter,
      }}
    >
      <HeaderComponent />
      <div className="contact-page">
        <TotalContact totalContact={buildContactListData?.length} />
        <ContactSearch />
        <ContactFilter filterList={filterList} />
        <ContactTable
          tableHeader={columns}
          contactList={buildContactListData}
        />
      </div>
      <ContactFilterDialog
        isOpen={isOpenDialog}
        handleOpenDialog={handleDialog}
      />
      <ShareDetailsDialog
        contactId={selectedContact}
        isOpen={isOpenShareDetailDialog}
        handleOpenDialog={handleShareDetailDialog}
      />
    </ContactContext.Provider>
  );
};

ContactPage.propTypes = {};

export default ContactPage;
