import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Menu, MenuItem } from "@mui/material";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deleteContactFilter } from "../../api-services/contact";
import ContactContext from "../../context/contact-context";

const ContactFilterItem = ({
  filterName = "",
  value = 0,
  filterId = "",
  filterSegment = {},
  onClickFilter,
  isSelected = false,
}) => {
  const queryClient = useQueryClient();
  const {
    handleDialog,
    selectedContactFilter,
    handleSelectContactFilter,
    setFilterData,
    setIsUpdateFilter,
  } = useContext(ContactContext);
  const { enqueueSnackbar } = useSnackbar();
  const [buttonElement, setButtonElement] = useState(null);
  const open = Boolean(buttonElement);

  const deleteFilter = useMutation(deleteContactFilter);
  const handleSelectFilter = () => {
    onClickFilter(filterId);

    setFilterData({
      filterName,
      filterSegment,
    });
  };
  const handleClick = (e) => {
    if (!!selectedContactFilter && filterId === selectedContactFilter) {
      e.stopPropagation();
    }
    setButtonElement(e.currentTarget);
  };
  const handleClose = (e) => {
    if (!!selectedContactFilter) {
      e.stopPropagation();
    }
    setButtonElement(null);
  };

  const handleEditFilter = (e) => {
    if (!!selectedContactFilter) {
      e.stopPropagation();
    }
    setFilterData({
      filterName,
      filterSegment,
    });
    setIsUpdateFilter(true);
    handleDialog();
    handleClose(e);
  };
  const handleDeleteFilter = (e) => {
    if (!!selectedContactFilter) {
      e.stopPropagation();
    }
    deleteFilter.mutate(selectedContactFilter, {
      onSuccess: () => {
        handleSelectContactFilter("");
        queryClient.invalidateQueries("filterList");
        enqueueSnackbar("The filter has been deleted", { variant: "success" });
      },
    });
    handleClose(e);
  };

  return (
    <div
      className={classNames("contact-filter-item__container", {
        selected: isSelected,
      })}
      onClick={handleSelectFilter}
    >
      <div>{filterName}</div>

      <div className="contact-filter-item__menu">
        <IconButton
          aria-label="filter item menu"
          onClick={handleClick}
          className="contact-filter-item__menu-icon"
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="contact-filter-menu"
          anchorEl={buttonElement}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "contact-filter-button",
          }}
        >
          <MenuItem onClick={handleEditFilter}>Edit filter</MenuItem>
          <MenuItem onClick={handleDeleteFilter}>Delete filter</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

ContactFilterItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
};

export default ContactFilterItem;
