import { useMutation, useQuery } from "react-query";
import {
  createContactFilter,
  getContactFilter,
  getContactList,
  getContactListByFilterId,
  updateContactFilter,
} from "../api-services/contact";

export const useContactList = () => {
  return useQuery("contactList", getContactList);
};

export const useCreateFilter = () => {
  return useMutation(createContactFilter);
};
export const useUpdateFilter = () => {
  return useMutation(updateContactFilter);
};

export const useContactFilter = () => {
  return useQuery("filterList", getContactFilter);
};

export const useGetContactListByFilter = (id) => {
  return useQuery(["contactListById", id], () => getContactListByFilterId(id), {
    enabled: Boolean(id),
  });
};
